<template>
  <div class="filter">
    <h2>Filtrar por {{ filterBy }}:</h2>
    <div class="form-control">
      <select
        name="time-recipe"
        @change="(event) => $emit('update:filter', event.target.value)"
      >
        <option v-for="(option, index) in options" :value="option" :key="index">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:filter'],
  props: {
    filterBy: {
      type: String,
      required: true,
    },
    currentDefault: {
      type: [String, Number],
    },
    options: {
      type: Array,
    },
  },
};
</script>
